<template>
  <div class="settings_index">

        <router-view></router-view>

  </div>
</template>

<script>

export default {
  name: 'SettingsIndex',
  data () {
    return {
    }
  }
}
</script>

<style>
</style>
